/* External dependencies */
import { Anchor, Col, Div, Row, Text } from 'atomize';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';
import * as ReactRedux from 'react-redux';

/* Local dependencies */
import formatMessage from '../../../intl/formatMessage';
import { formStyle, wrapStyle } from '../../form/FormStyles';
import PrimaryButton from '../../buttons/PrimaryButton';
import { requestVerificationCode, verifyCode } from '../../login/redux/actions';
import FormField from '../../form/FormField';
import { RootState } from '../../../redux/store';

export default function VerifyCode() {
  const dispatch = ReactRedux.useDispatch();
  const [verificationCode, setVerificationCode] = React.useState(null);
  const { error, loading, username } = ReactRedux.useSelector(
    (store: RootState) => store.login,
  );

  const [seconds, setSeconds] = React.useState(59);
  const minutes = '00';
  let interval;

  function startTimer(secs: number) {
    setSeconds(secs);

    interval = setInterval(() => {
      if (seconds) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval);
        interval = undefined;
      }
    }, 1000);
  }

  React.useEffect(() => {
    startTimer(seconds);

    return () => clearInterval(interval);
  }, [seconds]);

  function onSubmit(e) {
    e.preventDefault();

    dispatch(verifyCode(verificationCode));
  }

  function onGetNewCode() {
    startTimer(59);

    dispatch(requestVerificationCode(username));
  }

  const isInvalid = !username || !verificationCode;

  const labelPhone = formatMessage('phone');
  const code = formatMessage('code');
  const phonePlaceholder = formatMessage('phonePlaceholder');
  const codePlaceholder = formatMessage('codePlaceholder');
  const sentSMSCode = formatMessage('sentSMSCode');
  const getAnotherCode = formatMessage('getAnotherCode');
  const getNewVerificationCode = formatMessage('getNewVerificationCode');

  return (
    <Div {...wrapStyle}>
      <form onSubmit={onSubmit} style={formStyle}>
        <Row m="0">
          <Col
            border={{ b: '1px solid' }}
            textAlign="left"
            borderColor="basic_border_color"
          >
            <Text tag="h1" textSize="18px" textWeight="bold" m="1rem">
              {<FormattedMessage id="loginSignInWithSMS" />}
            </Text>
          </Col>
        </Row>
        <Row p="1rem" m="0">
          <FormField
            disabled={true}
            fieldDescription={sentSMSCode}
            label={labelPhone}
            inputValue={username}
            inputType="tel"
            inputName="username"
            placeholder={phonePlaceholder}
            required={true}
          />
          <FormField
            disabled={loading}
            label={code}
            inputValue={verificationCode}
            inputType="number"
            inputName="verificationCode"
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder={codePlaceholder}
            required={true}
          />
          {error && (
            <Col>
              <Text tag="span" textColor="red">
                {<FormattedMessage id={error.message} />}
              </Text>
            </Col>
          )}
        </Row>
        <Row
          m="0"
          border={{ t: '1px solid' }}
          borderColor="basic_border_color"
          p="1rem"
        >
          <Col d="flex" justify="flex-start" align="center">
            <Text tag="span" textColor="cancel_border_color">
              {seconds === 0 ? (
                <Anchor onClick={onGetNewCode}>
                  <Text tag="span" textSize="caption">
                    {getNewVerificationCode}
                  </Text>
                </Anchor>
              ) : (
                `${getAnotherCode}
              ${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
              )}
            </Text>
          </Col>
          <Col d="flex" justify="flex-end">
            <PrimaryButton disabled={isInvalid || loading} loading={loading} onClick={onSubmit}>
              {<FormattedMessage id="next" />}
            </PrimaryButton>
          </Col>
        </Row>
      </form>
    </Div>
  );
}
